@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* ============================================================================================== */
/* ======================================== ANTD IMPORT ======================================== */
/* ============================================================================================== */
@import '~antd/dist/antd.css';

html,
body,
#root {
	width: 100vw;
	height: 100vh;
	font-family: 'Inter' !important;
	font-weight: 400;
	overscroll-behavior: none;
}

/* ============================================================================================== */
/* =================================== COMPONENT CLASSES ======================================== */
/* ============================================================================================== */

@layer components {
	.slide-animation-close {
		@apply transition-[height] duration-500 overflow-hidden h-0 text-[0];
	}
	.slide-animation-open {
		@apply transition-[height] duration-500 overflow-hidden h-[52px];
	}
	.collapse-animation-close {
		@apply transition-[height] duration-500 overflow-hidden h-0;
	}
	.collapse-animation-open {
		@apply transition-[height] duration-500 overflow-y-scroll overflow-x-hidden min-h-fit;
	} 
	.bridgelinx-logo {
		@apply w-1/2 h-auto mb-12 mx-auto;
	}

	.btn-primary {
		@apply !h-10 !rounded !border-0 !bg-primary-800 !px-6 !font-semibold !text-white;
		@apply focus:!bg-primary-800 !text-white;
		@apply active:!bg-primary-800 !text-white;
	}
	.btn-primary:hover {
		@apply !bg-primary-800 !text-white ;
	}
	.btn-primary:disabled {
		@apply !bg-black-50 !text-grey-100 !cursor-not-allowed !text-sm !font-light;
	}
	.btn-primary:hover:disabled {
		@apply !bg-black-50 !text-grey-100 !cursor-not-allowed !text-sm !font-light;
	}

	.btn-action {
		@apply !h-10 !rounded !border-0 !bg-primary-50 !px-3 !font-medium text-sm !text-primary-800;
		@apply focus:!bg-primary-50 focus:!text-primary-800;
		@apply active:!bg-primary-50 active:!text-primary-800;
	}
	.btn-action:hover {
		@apply !bg-primary-50 !text-primary-800;
	}
	.btn-action:disabled {
		@apply !bg-black-50 !text-grey-100 !cursor-not-allowed !text-sm !font-medium;
	}
	.btn-action:hover:disabled {
		@apply !bg-black-50 !text-grey-100 !cursor-not-allowed !text-sm !font-medium;
	}

	.btn-secondary {
		@apply !h-10 !rounded !border-0 !bg-black-300 !px-6 !font-bold text-sm !text-white;
		@apply hover:!bg-black-300 hover:!text-white;
		@apply focus:!bg-black-300 focus:!text-white;
		@apply active:!bg-black-300 active:!text-white;
	}
 
	.btn-back {
		@apply !h-10 !rounded !border-0 !bg-[#F5F5F5] !px-6 !font-medium text-sm !text-black-400 leading-4;
		@apply hover:!bg-[#F5F5F5] hover:!text-black-400;
		@apply focus:!bg-[#F5F5F5] focus:!text-black-400;
		@apply active:!bg-[#F5F5F5] active:!text-black-400;
	}

	.btn-dashed {
		@apply flex w-max items-center rounded-full border border-dashed text-xs font-bold shadow-none;
		@apply border-primary-800 bg-primary-50 text-primary-800;
		@apply hover:border-primary-800 hover:bg-primary-50 hover:text-primary-800;
		@apply active:border-primary-800 active:bg-primary-50 active:text-primary-800;
		@apply focus:border-primary-800 focus:bg-primary-50 focus:text-primary-800;
	}
	
	.btn-primary-highlight {
		@apply !h-10 !rounded !border-0 !bg-primary-50 !px-6 !font-inter !font-medium !text-primary-800 hover:!bg-primary-50 hover:!text-primary-800 focus:!bg-primary-50 focus:!text-primary-800 active:!bg-primary-50 active:!text-primary-800;
	}
	
	.btn-submit {
		@apply !h-full !rounded-md !border-0 !px-10 !font-medium !shadow-none;
		@apply !bg-green-400 !text-white;
		@apply hover:!bg-green-400 hover:!text-white;
		@apply active:!bg-green-400 active:!text-white;
		@apply focus:!bg-green-400 focus:!text-white;
	}
	
	.btn-ghost {
		@apply !flex !w-max !items-center !border-0 !bg-transparent !p-0 !text-sm !text-primary-800 !underline !shadow-none;
		@apply !text-primary-800;
		@apply hover:!text-primary-800 hover:!underline;
		@apply active:!text-primary-800 active:!underline;
		@apply focus:!text-primary-800 focus:!underline;
	}
	
	.btn-delete {
		@apply !flex !w-max !items-center !rounded !border !text-sm !font-medium !shadow-none;
		@apply !border-[#FCE0E0] !bg-[#FCE0E0] !text-red-400;
		@apply hover:!border-[#FCE0E0] hover:!bg-[#FCE0E0] hover:!text-red-400;
		@apply active:!border-[#FCE0E0] active:!bg-[#FCE0E0] active:!text-red-400;
		@apply focus:!border-[#FCE0E0] focus:!bg-[#FCE0E0] focus:!text-red-400;
	}

	.order-type-pill {
		@apply rounded py-1.5 px-2 text-xs font-semibold text-white w-fit;
	}

	.enable-pointer { 
		@apply !pointer-events-auto;
	}

	.disable-pointer { 
		@apply !pointer-events-none;
	}
}


/* ============================================================================================== */
/* ======================================== ANTD IMPORT ======================================== */
/* ============================================================================================== */

.btn-primary {
	@apply h-11 rounded border-0 bg-primary-800 px-6 font-inter font-bold text-white hover:bg-primary-800 hover:text-white focus:bg-primary-800 focus:text-white active:bg-primary-800 active:text-white;
}

.btn-primary-highlight {
	@apply h-11 rounded border-0 bg-primary-50 px-6 font-inter font-bold text-primary-800 hover:bg-primary-50 hover:text-primary-800 focus:bg-primary-50 focus:text-primary-800 active:bg-primary-50 active:text-primary-800;
}

.btn-header {
	@apply mr-3 h-full rounded-md border-0 shadow-none;
	@apply hover:text-primary-800;
	@apply active:text-primary-800;
	@apply focus:text-primary-800;
}

.btn-header-active {
	@apply btn-header font-bold;
	@apply bg-primary-50 text-primary-800;
	@apply hover:bg-primary-50 hover:text-primary-800;
	@apply active:bg-primary-50 active:text-primary-800;
	@apply focus:bg-primary-50 focus:text-primary-800;
}

.btn-place-order {
	@apply h-10 rounded-md border-0 px-10 font-bold shadow-none;
	@apply bg-green-500 text-white;
	@apply hover:bg-green-500 hover:text-white;
	@apply active:bg-green-500 active:text-white;
	@apply focus:bg-green-500 focus:text-white;
}

.btn-ghost {
	@apply flex w-max items-center border-0 bg-transparent p-0 text-sm text-primary-800 underline shadow-none;
	@apply text-primary-800;
	@apply hover:text-primary-800 hover:!underline;
	@apply active:text-primary-800 active:underline;
	@apply focus:text-primary-800 focus:underline;
}

.btn-delete {
	@apply flex w-max h-10 px-10 items-center rounded border text-sm font-bold shadow-none;
	@apply border-red-500 bg-red-50 text-red-500;
	@apply hover:border-red-500 hover:bg-red-50 hover:text-red-500;
	@apply active:border-red-500 active:bg-red-50 active:text-red-500;
	@apply focus:border-red-500 focus:bg-red-50 focus:text-red-500;
}

.flex-column-box {
	@apply flex flex-col items-center justify-center;
}

.input-field {
	@apply flex h-12 items-center rounded border-gray-400 font-inter;
}

.counter-button {
	@apply absolute z-10;
}

.left-counter-button {
	@apply counter-button;
	left: 0px;
	margin-top: 8px;
	margin-left: 20px;
	background: white;
}
.right-counter-button {
	@apply counter-button;
	right: 0px;
	margin-top: 8px;
	margin-right: 20px;
	background: white;
}

/* ============================================================================================== */
/* ======================================== ANTD IMPORT ======================================== */
/* ============================================================================================== */

/* ================== LABELS ================== */
.ant-form-item-label {
	font-family: 'Inter';
	font-weight: 500;
	padding-bottom: 4px !important;
}
.ant-form-item-label > label {
	font-size: 12px;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	display: none;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
	display: inline-block;
	content: '*';
	color: #ff0000;
	margin-left: 4px;
}

/* ================= CHECKBOX ================= */
.ant-checkbox-inner {
	border: 2px solid;
}

/* =============== ADDON FIELDS =============== */
.ant-input-group,
.ant-input-group input {
	height: 100%;
}
.ant-input-group > *:first-child,
.ant-input-number-group > *:first-child {
	border-radius: 5px 0px 0px 5px;
}
.ant-input-group > *:last-child,
.ant-input-number-group > *:last-child {
	border-radius: 0px 5px 5px 0px;
}

.ant-input-affix-wrapper > input {
	background-color: transparent;
}

/* =============== TABLE =============== */
.ant-table-thead > tr > th,
.ant-table-thead th.ant-table-column-sort {
	height: 48px !important;
	font-family: 'Inter';
	font-weight: 600;
	font-size: 0.75rem;
	line-height: 1rem;
	color: #676767;
	background: #f8f8f8 !important;
	padding: 16px !important;
}

.ant-table-thead > tr > th::before {
	display: none;
}

.ant-table-cell {
	font-size: 0.75rem;
	line-height: 1rem;
	vertical-align: middle;
}
td.ant-table-column-sort {
	background-color: #ffffff;
}

.ant-table-wrapper,
.ant-table-wrapper > div,
.ant-table-wrapper > div > div,
.ant-table-wrapper > div > div > div,
.ant-table-wrapper > div > div > div > div {
	height: 100%;
}

#inheritSpan span.ant-radio + span {
    padding-right: 8px;
    padding-left: 8px;
    width: inherit;
}

/* =============== TABS =============== */
.ant-tabs-tab {
	margin: 0px 6px !important;
}

/* =============== LAYOUT =============== */
.ant-layout {
	background-color: white;
	height: 100%;
}

.ant-layout-header {
	background: white;
	line-height: 120%;
}

.overflow-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* =============== MESSAGE =============== */

.ant-message-notice-content {
    @apply rounded-sm
}

/* ============================================================================================== */
/* ======================================== FORM ======================================== */
/* ============================================================================================== */
.InputField > .ant-input-group {
	height: 100%;
}
.InputField .ant-input-group-addon {
	height: 100%;
	font-family: 'Inter';
	color: #333333;
	background-color: #f2f2f2;
	border: 0;
	padding: 0px 12px;
}
.InputField .ant-input {
	height: 100%;
	border: 0;
}
.InputField > .ant-input-group > *:first-child {
	border-radius: 5px 0px 0px 5px !important;
}
.InputField > .ant-input-group > *:last-child {
	border-radius: 0px 6px 6px 0px !important;
}
.ant-form-item-has-error .InputField {
	border: 1px solid #ff0000 !important;
}
.ant-form-item-has-error > .ant-form-item-control > div > div > span > .UploadImages {
	border: 1px dashed #ff0000 !important;
}
.ant-form-item-has-error .UploadImages .ant-btn {
	border: 1px solid #ff0000 !important;
}

.requiredErrorField {
	margin-top: 5px;
	text-align: center;
}

.ant-form-item-explain-error {
	margin-top: 5px;
    color: #ff4d4f;
}

/* ============================================================================================== */
/* ======================================== INPUT FIELDS ======================================== */
/* ============================================================================================== */
.InputField,
.SelectField > .ant-select-selector,
.ant-select-selection-search-input {
	height: 48px;
	min-height: 48px !important;
	font-family: 'Inter';
	display: flex;
	align-items: center;
	border: 1px solid #e0e0e0;
	border-radius: 5px !important;
	color: #333333;
}
.ant-input-affix-wrapper > input.ant-input {
	padding: 2px;
}
.InputField > .ant-input-number-input-wrap {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}

.ant-form-vertical .ant-form-item-label > label {
	color: #787878;
	font-weight: 600;
	font-size: 12px;
	margin-bottom: 2px;
}

.Upload div {
	width: 100%;
}
.Upload .ant-upload {
	display: flex;
	justify-content: center;
}

.QuantityField {
	width: 100%;
	height: 40px;
	min-height: 40px !important;
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 700;
	border-radius: 6px;
	padding: 12px 24px;
	text-align: center;
}

.ant-radio-group {
	display: flex;
}
.ant-radio-button-wrapper {
	height: 48px;
	font-family: 'Inter';
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px !important;
	padding: 12px;
	margin-right: 12px;
	border-left-width: 1px;
	white-space: nowrap;
}
.ant-radio-button-wrapper > span {
	min-width: 80px;
	text-align: center;
}

.ant-radio-button-wrapper:not(:first-child)::before {
	display: none !important;
}
.ant-radio-group > .ant-radio-button-wrapper-checked {
	background: #0066cc !important;
	color: #ffffff !important;
	border-color: #0066cc !important;
}

.ant-modal-content {
	border-radius: 6px;
}
.ant-modal-header {
	padding: 0px 0px;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.ant-modal-body {
	padding: 0px;
}

.InviteMembersModal {
	width: 45vw !important;
}
.InviteMembersModal > .ant-modal-content > .ant-modal-body {
	width: 45vw;
	max-height: calc(90vh - 106px);
	overflow-y: scroll;
}

.OrderSummaryModal {
	width: 70vw !important;
}
.OrderSummaryModal > .ant-modal-content > .ant-modal-body {
	width: 70vw;
	max-height: calc(90vh - 106px);
	overflow-y: scroll;
}

.EmailsField > .ant-select-selector {
	height: 48px;
	min-height: 48px !important;
	display: flex;
	align-items: center;
	border: 1px solid #e0e0e0;
	border-radius: 5px !important;
	color: #333333;
	margin: 0px;
	padding: 0px 12px;
}
.EmailsField .ant-select-selection-overflow {
	height: 46px;
}
.ant-select-selection-overflow-item-suffix {
	top: unset !important;
}

#containerSpecifics > .ant-form-item-has-error .ant-form-item-control-input {
	border: 1px solid #ff0000;
}

#DashboardTabs > .ant-tabs-nav {
	height: 100%;
	margin: 0px !important;
}

#DashboardTabs .ant-tabs-tab {
	margin: 0px !important;
	border: 0px;
	border-radius: 12px 12px 0px 0px;
	background: white;
}
#DashboardTabs .ant-tabs-tab-active {
	background: white;
}

#CorporateInfo > .ant-dropdown-menu-title-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.MapVehicleSelect > .ant-select-selector {
	height: 48px !important;
	display: flex;
	align-items: center;
	border-radius: 5px !important;
}

.MapVehicleSelect > .ant-select-selector > .ant-select-selection-item {
	height: 48px;
	display: flex;
	align-items: center;
}

.ant-drawer-content-wrapper {
	width: 100% !important;
	box-shadow: none !important;
}

.ant-timeline-item-content {
	min-height: max-content !important;
}

.ant-btn-primary {
	background-color: #1890ff;
}

.CustomHeightSelector > .ant-select-selector {
	height: 100%;
}

.no-hover{
	pointer-events: none;
}

/* ============================================================================================== */
/* ============================================ Table =========================================== */
/* ============================================================================================== */

.ant-table.ant-table-small .ant-table-tbody > tr > td {
	padding: 15px;
}
